<template>
  <a-drawer :title="title" width="60%" :placement="placement" :closable="true" :visible="visible" @close="onSwitch(false)" v-if="visible">
    <suy-table :reload="loadData" v-model:columns="columns" >
      <a-table :columns="[sortColumn,...columns]" :loading="loading" :data-source="listData" :pagination="pagination" :rowKey="(record,index)=>{return index}" @change="handleTableChange">
        <template #sort="{ index }">
          {{index+1}}
        </template>

      </a-table>
    </suy-table>
  </a-drawer>
</template>
<script>
import { toRefs, reactive } from 'vue'
import { truckGpsPage } from '@/api/transport/truck'
import SuyTable from '@/components/SuyTable'
export default {
  components: {
    SuyTable
  },
  setup (props, context) {
    const state = reactive({
      gpsId: '',
      carId: '',
      visible: false,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      listData: [],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '车架号',
          dataIndex: 'carNo',
          key: 'carNo',
          ellipsis: true

        },
        {
          title: '地址',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true
        },
        {
          title: '操作人员',
          dataIndex: 'operator',
          key: 'operator',
          ellipsis: true
        },
        {
          title: '修改时间',
          dataIndex: 'recordTime',
          key: 'recordTime',
          ellipsis: true
        },
        {
          title: '说明',
          dataIndex: 'remark',
          key: 'remark',
          ellipsis: true
        }
      ]
    })
    const loadData = () => {
      state.loading = true
      truckGpsPage(state.carId, {
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then((res) => {
        if (res.code === 10000) {
          if (res.data.records !== []) {
            state.gpsId = res.data.records[0].gpsId
          }
        }
        state.listData = res.data.records
        state.pagination.current = res.data.current
        state.pagination.pageSize = res.data.size
        state.pagination.total = res.data.total
      })
      state.loading = false
    }
    const onSwitch = (parameter) => {
      if (parameter === false) {
        state.listData = []
      }
      state.visible = false
    }
    const oncarid = (parameter) => {
      state.carId = parameter
      onSwitch(true)
      loadData()
    }
    const onvisible = (parameter) => {
      state.visible = parameter
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    return {
      oncarid,
      onSwitch,
      onvisible,
      ...toRefs(state),
      loadData,
      handleTableChange
    }
  }
}
</script>
<style lang="less" scoped>
</style>
