<template>
  <div class="page-header">
    <div style="display:flex;align-items: center;">
      <a-page-header style="border: 1px solid rgb(235, 237, 240);margin:0" title="订单信息" />
      <SyncOutlined style="cursor: pointer;margin-right:50px;" @click="replaceLoad" />
      <a-button style="margin-right:15px" type="primary" @click="QRCshow">生成小程序二维码</a-button>
      <!-- <a-select v-model:value="payType" style="width: 140px;margin-right:15px" placeholder="选择支付方式">
        <a-select-option :value="0">聚合支付码</a-select-option>
        <a-select-option :value="1">微信支付</a-select-option>
        <a-select-option :value="2">支付宝支付</a-select-option>
      </a-select>
      <a-button type="primary" @click="QRCpayImg">生成</a-button> -->
      <a-button type="primary" @click="openPayQRC">生成支付码</a-button>
    </div>
    <div class="page-header-right">
      <div class="page-header-right-text">
        <span>
          合同号： <a-typography-paragraph style="color:red;margin:0;display:inline" copyable>{{ id
          }}</a-typography-paragraph>
        </span>
      </div>
      <div class="page-header-right-text">
        <span>推送时间：{{ pushTime }}</span>
      </div>
      <div class="page-header-right-text">
        <img src="../../../assets/images/common-carrier.png">
        <span>承运公司：{{ carrierType.label }}</span>
      </div>
      <div class="page-header-right-text">
        <img src="../../../assets/images/operator.png">
        <span>业务员：{{ salesman }}</span>
      </div>
    </div>
  </div>
  <!-- 评论信息 -->
  <a-drawer v-model:visible="evaluation" title="订单评价" footer='' width="50%">
    <div v-if="remarkTime === null || remarkTime === '' || remarkTime === undefined">
      <h1>暂无评论信息</h1>
    </div>
    <div v-else class="evaluation-data">
      <div class="left-evaluation">
        <div class="title-data">
          <div>评价时间: {{ remarkTime }}</div>
        </div>
        <!-- 评论内容 -->
        <div v-for="item in evaluationList" :key="item.no">
          <div>{{ item.no }}. {{ item.title }}</div>
          <div style="color:#1890FF">
            <span v-if="item.option.value === 2">A</span> <span v-if="item.option.value === 1">B</span> <span
              v-if="item.option.value === 0">C</span>
            {{ item.option.label }}
          </div>
          <a-divider style="border-color: #ccc;margin:10px 0" dashed />
        </div>
        <div v-if="proposal !== null">
          <div>10. 其他建议</div>
          <div>{{ proposal }}</div>
        </div>
      </div>
      <div class="right-reply" style="width:50%;">
        <a-textarea v-model:value="reply" placeholder="请输入回复内容" :rows="4" />
        <p class="send-btn">
          <a-button type="primary" @click="setReply">发送</a-button>
        </p>
        <a-divider style="border-color: #ccc;margin:10px 0" />
        <div style="display:flex;" v-for="item in replyList" :key="item.time">
          <div style="width:60px">{{ item.type.label }}：</div>
          <div>
            {{ item.message }}
            <div style="color:#B8B8B8;font-size:14px">{{ item.time }}</div>
          </div>
        </div>
        <a-divider style="border-color: #ccc;margin:10px 0" />
      </div>
    </div>
  </a-drawer>
  <div class="showbox">
    <div class="showbox-min">
      <img src="@/assets/images/sum.png">
      <div>
        <div style="flex-direction: row;position:relative">车辆总数<span class="num-data" style="color:skyblue">{{
          orderVehicleTotalNum }}</span></div>
        <div style="flex-direction: row;position:relative">在途车辆<span class="num-data" style="color:#29CC97">{{
          orderVehicleTransNum }}</span></div>
        <div style="flex-direction: row;position:relative">在库车辆<span class="num-data" style="color:orange">{{
          orderVehicleNotTransNum }}</span></div>
      </div>
    </div>
    <div class="showbox-min">
      <img src="@/assets/images/pact.png">
      <div>
        <span style="color:rgb(64,154,254)">订单结算信息<img style="cursor: pointer;" src="@/assets/images/examine.png"
            @click="settleAccountsVisible" /></span>
        <a @click="settleAccountsVisible">查看结算</a>
      </div>
    </div>
    <div class="showbox-min">
      <img src="@/assets/images/check.png">
      <div>
        <span style="color:rgb(64,154,254)">车辆运输合同<img style="cursor: pointer;" src="@/assets/images/examine.png"
            @click="modalVisible" /></span>
        <a @click="modalVisible">查看合同</a>
      </div>
    </div>
    <div class="showbox-min">
      <img src="@/assets/images/on-passage.png">
      <div>
        <span style="color:rgb(64,154,254)">订单评论信息<img style="cursor: pointer;" src="@/assets/images/examine.png"
            @click="evaluationLook" /></span>
        <a @click="evaluationLook">查看评论</a>
      </div>
    </div>
  </div>
  <OrderInfo v-if="orderInfoShow" :id="id" ref="orderInfoRef" />
  <settleAccounts :id="id" ref="settleAccounts" :customerName="customerName" :orderSettlement="orderSettlement" />

  <a-tabs v-model:activeKey="active">
    <a-tab-pane key="1" tab="订单车辆">
      <div style="background-color:white">
        <a-table :columns="columnsLeft" :data-source="dataLeft" :pagination="pagination" @change="handleTableChange"
          :row-key="record => record.orderVehicleId" :loading="loading">
          <template #title>
            <a-form :model="formState">
              <a-row :gutter="24" justify="space-between">
                <a-col :span="8">
                  <a-form-item label="车辆状态">
                    <a-select placeholder="请选择车辆状态" v-model:value="formState.inStockType">
                      <a-select-option :value="0">全部</a-select-option>
                      <a-select-option :value="1">在途</a-select-option>
                      <a-select-option :value="2">在库</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="车牌号">
                    <a-input v-model:value="formState.insignia" placeholder="请输入车牌号码" />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-button type="primary" @click="searchVehicleData">搜索</a-button>
                  <a-button style="margin-left:10px" @click="abnormalReportMode" type="primary" danger>异常
                    {{ abnormaltotal }}条</a-button>
                  <a-button style="margin-left:10px" @click="resetData">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </template>
          <template #brand="{ record }">
            <div>
              {{ record.brand + record.model }}
            </div>
          </template>
          <template #special="{ record }">
            <div>
              <span>{{ record.isNoticeReleaseCar?.value === 1 ? '等通知放车,' : '' }}</span>
              <span>{{ record.isDeliveryCard?.value === 1 ? '交车条,' : '' }}</span>
              <span>{{ record.isInspectionPay?.value === 1 ? '验车打款,' : '' }}</span>
            </div>
          </template>
          <template #vehicleTransStatus="{ record }"><span v-if="record.transportType != null">{{
            record.transportType.label }}-</span>
            <span v-if="record.transStatus != null">{{ record.transStatus.label }}</span></template>
          <template #action="{ record }">
            <div>
              <a-space>
                <a @click="onLog(record)">日志</a>
                <a @click="checkOut(record.orderVehicleId)">运输</a>
                <a @click="orderVehicleGps(record.orderVehicleId)">位置</a>
                <a v-show="record.isPlan > 0" @click="inspectionCommissionClick(record.orderVehicleId)">委托</a>
              </a-space>
            </div>
            <div>
              <a-space>
                <a @click="abnormalOpen(record)">异常</a>
                <a @click="vehicleImg(record.orderVehicleId, record.vinNo)">图片</a>
                <a @click="lookPlan(record)">方案</a>
              </a-space>
            </div>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="运输车" force-render>
      <div style="background-color:white" v-if="!loading">
        <a-spin v-if="loading" />
        <div style="margin-bottom:10px;padding:15px" v-for="(item, index) in transportCart" :key="index">
          <div class="transport-cart">
            <div>
              <p>大车车牌</p>
              <span>{{ item.carNo }}</span>
            </div>
            <div>
              <p>承运商</p>
              <span>{{ item.carrierName }}</span>
            </div>
            <div>
              <p>线路名称</p>
              <span>{{ item.lineName }}</span>
            </div>
            <div>
              <p>发车人</p>
              <span>{{ item.dispatcher }}</span>
            </div>
            <div>
              <p>运输状态</p>
              <span>{{ item.truckStatus.label }}</span>
            </div>
            <div style="width:200px">
              <p>当前位置</p>
              <span>
                {{ item.latestLocation }}
              </span>
            </div>
            <div>
              <a style="color:#FFB159" @click="historyPosition(index)">查看历史位置</a>
              <a-typography-paragraph class="copy" style="margin:0;display:inline"
                :copyable="{ text: item.latestLocation }"></a-typography-paragraph>
            </div>
          </div>
          <div style="display:flex">
            <span>订单车辆：</span>
            <div>
              <a-tag v-for="(item, index) in tagList[index].vehicleList" :key="index" color="#108ee9"
                @click="checkOut(item.value)" style="cursor: pointer;">{{ item.label }}</a-tag>
            </div>
          </div>
        </div>
        <a-result title="暂无数据" v-if="transportCart.length == 0">
          <template #icon>
            <BarChartOutlined />
          </template>
        </a-result>
        <div class="page" v-if="transportCart.length > 0">
          <a-pagination :total="transportCart.length" :show-total="total => `共${total}条`" :page-size="10"
            v-model:current="current" :showQuickJumper="true" :showSizeChanger="true" />
        </div>
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="验车信息">
      <div style="background:#fff;padding:15px">
        <p style="font-size:20px">接单信息: {{ remark === '' ? '暂无接单信息' : remark }}</p>
        <a-table :columns="inspectionColumns" :data-source="inspectionData" :row-key="record => record.id"
          :loading="inspectionLoading" bordered size="small" :pagination="inspectionPagination" @change="handleChange">
          <template #orderVehicles="{ record }">
            <span v-for="item in record.orderVehicles" :key="item.value">
              <span>{{ item.vinNo }}</span>
              <a-tag color="processing">{{ item.picNum }}张</a-tag>
            </span>
          </template>
          <template #operation="{ record }">
            <a-button size="small" @click="inspectorShow = true, detailData = record">查看验车人</a-button>
          </template>
        </a-table>
        <!-- 查看验车人信息 -->
        <a-modal v-model:visible="inspectorShow" title="查看验车人" :footer="null">
          <a-col style="width:100%">
            <div style="width:60%">
              <a-form-item label="承运商">
                {{ detailData.carrierName }}
              </a-form-item>
              <a-form-item label="司机姓名">
                {{ detailData.driverName }}
              </a-form-item>
              <a-form-item label="联系电话">
                {{ detailData.driverTel }}
              </a-form-item>
              <a-form-item label="身份证号">
                {{ detailData.driverIdNumber }}
              </a-form-item>
              <a-form-item label="司机类型">
                {{ detailData.driverType === null ? '' : detailData.driverType.label }}
              </a-form-item>
            </div>
          </a-col>
        </a-modal>
      </div>
    </a-tab-pane>
  </a-tabs>
  <AbnormalTable ref="abnormalTableRef" />
  <!-- 委托 -->
  <InspectionCommission ref="inspectionCommission" />
  <!-- <a-modal v-model:visible="abnormalModal" title="异常列表" v-if="abnormalModal" footer='' width="60%">
    <a-table :columns="abnormalColumns" :data-source="abnormalDataLeft" :pagination="abnormalPagination"
      :row-key="record => record.vinNo" :loading="abnormalLoading">
      <template #fileUrl="{ record }">
        <a-image width="100px" height="50px" v-if="record.fileUrl" :src="`${record.fileRealUrl}`" />
      </template>
    </a-table>
  </a-modal> -->
  <!-- 图片查看 -->
  <VehicleImg ref="vehicleImgRef" />
  <!-- 二维码生成 -->
  <a-modal v-model:visible="QRC" title="二维码" ok-text="下载二维码" @ok="downQRC()">
    <div class="QRC" v-show="status === 0">
      <a-spin v-show="QRCloading" tip="Loading..." />
      <div v-show="!QRCloading" style="text-align:center;margin-bottom:10px;width:100%">
        <div>起：{{ startAddress }}</div>
        <div>终：{{ endAddress }}</div>
        <div style="display:flex;justify-content: space-around;">
          <div>车架号：<a-tag color="green" v-for="(item, index) in dataLeft" :key="index">{{ item.vinNo }}</a-tag>
          </div>
        </div>
      </div>
      <img v-show="!QRCloading" :src="QRCurl" style="width:50%" />
    </div>
    <div class="QRC" v-show="status === 1 && QRCcontext === ''">
      <a-spin v-show="QRCloading" tip="Loading..." />
      <div v-show="!QRCloading" style="text-align:center;margin-bottom:10px">
        <div>起：{{ startAddress }}</div>
        <div>终：{{ endAddress }}</div>
        车架号：<a-tag color="green" v-for="(item, index) in dataLeft" :key="index">{{ item.vinNo }}</a-tag>
      </div>
      <QrcodeVue id="QRCpayImg" v-show="!QRCloading && payUrl !== ''" :value="payUrl" :size="200" level="H" />
    </div>
    <div v-show="QRCcontext !== ''" style="text-align: center;">{{ QRCcontext }}</div>
  </a-modal>

  <a-modal v-model:visible="logShow" title="聊天日志" v-if="logShow" footer='' width="50%">
    <Chat :orderId="chatObjct.orderId" :orderVehicleId="chatObjct.orderVehicleId"></Chat>
  </a-modal>

  <position ref="position" />
  <vehicleTable ref="vehicleTable" />
  <vehicleGps ref="vehicleGps" />
  <ShippingPlan ref="shippingRef" :windowShow="windowShow" :orderId="orderId" :orderVehicleId="orderVehicleId" />
  <!-- 异常列表 -->
  <a-modal v-model:visible="abnormalReportShow" title="异常列表" footer='' width="50%">
    <AbnormalReport :vinNo="tableDetail.vinNo" :windowShow="abnormalReportShow" :vehicleDetail="tableDetail"
      @statusShow="abnormal" />
  </a-modal>
  <!-- 支付二维码 -->
  <PayQRC ref="payQRCref" />
  <!-- 小程序码 -->
  <AppleQRC ref="appleQRCref" :detailData="detailData" />
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import Chat from '../../components/chat/index'
import position from './position.vue'
import { pageOrderId as orderExceptionPage, orderRemark, orderMessage, replyMessage } from '@/api/transport/orderException'
import vehicleTable from './carTable'
import OrderInfo from './orderInfo.vue'
import settleAccounts from './settleAccounts.vue'
import { message } from 'ant-design-vue'
import vehicleGps from './carGps.vue'
import { incompleteDetail } from '@/api/transport/truck'
import { vehicleDetail, carPicsGroup, fileDownload, getVehicles } from '@/api/global'
import { SyncOutlined, BarChartOutlined } from '@ant-design/icons-vue'
import { QRCurl, QRCpayurl, QRCpayurlYST } from '@/api/transport/inspection'
import { driverPage, remarkData } from '@/api/transport/inspection'
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas'
import InspectionCommission from '@/views/components/inspectionCommission'
import ShippingPlan from '@/views/components/shippingPlan'
import AbnormalReport from '@/views/components/transport/comon/abnormalReport.vue'
import AbnormalTable from '@/views/components/abnormalTable'
import PayQRC from '@/views/components/payQRC'
import AppleQRC from '@/views/components/appleQRC'
import VehicleImg from '@/views/components/vehicleImg'
export default {
  components: {
    position,
    vehicleTable,
    vehicleGps,
    OrderInfo,
    settleAccounts,
    SyncOutlined,
    BarChartOutlined,
    AbnormalReport,
    InspectionCommission,
    ShippingPlan,
    QrcodeVue,
    Chat,
    PayQRC,
    AbnormalTable,
    AppleQRC,
    VehicleImg
  },
  setup () {
    const router = useRouter()
    const orderInfoRef = ref(null)
    const vehicleImgRef = ref(null)
    const abnormalTableRef = ref(null)
    const payQRCref = ref(null)
    const appleQRCref = ref(null)
    const state = reactive({
      chatObjct: {},
      logShow: false,
      QRC: false,
      orderInfoShow: false,
      conventionaLoad: false,
      payQRC: false,
      windowShow: false,
      QRCloading: false,
      evaluation: false,
      abnormalReportShow: false,
      inspectionLoading: false,
      inspectorShow: false,
      payType: null,
      activeKey: 1,
      active: '1',
      totalPage: 0,
      pageSize: 30,
      QRCcontext: '',
      startAddress: '',
      endAddress: '',
      remark: '',
      orderId: '',
      orderVehicleId: '',
      injuryCurrent: 1,
      reply: '',
      QRCurl: '',
      payUrl: '',
      proposal: '',
      remarkTime: '',
      status: 0,
      orderStatus: '',
      id: router.currentRoute.value.params.orderId,
      vinNo: '',
      customerName: router.currentRoute.value.params.customerName,
      orderSettlement: router.currentRoute.value.params.orderSettlement,
      columnsLeft: [
        {
          title: '车型',
          key: 'model',
          width: '7%',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '运费',
          dataIndex: 'freight',
          key: 'freight'
        },
        {
          title: '取车费',
          key: 'placeInFee',
          dataIndex: 'placeInFee'
        },
        {
          title: '送车费',
          key: 'takeOutFee',
          dataIndex: 'takeOutFee'
        },
        {
          title: '附加费',
          key: 'additionalFeeTotal',
          dataIndex: 'additionalFeeTotal'
        },
        {
          title: '毛利',
          key: 'grossProfit',
          dataIndex: 'grossProfit'
        },
        {
          title: '毛利月份',
          key: 'profitMonth',
          dataIndex: 'profitMonth'
        },
        {
          title: '预计利润',
          key: 'estGrossProfit',
          dataIndex: 'estGrossProfit'
        },
        {
          title: '预计发运成本',
          key: 'estTransportCost',
          dataIndex: 'estTransportCost'
        },
        {
          title: '车牌号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },
        {
          title: '车辆状态',
          key: 'vehicleTransStatus',
          dataIndex: 'vehicleTransStatus',
          slots: {
            customRender: 'vehicleTransStatus'
          }
        },
        {
          title: '城市',
          key: 'location',
          dataIndex: 'location',
          width: '11%'
        },
        {
          title: '特殊需求',
          width: '6%',
          dataIndex: 'special',
          slots: {
            customRender: 'special'
          }
        },
        {
          title: '调度方',
          dataIndex: 'currentShipper'
        },
        {
          title: '交车',
          key: 'isDelivery.label',
          dataIndex: 'isDelivery.label'
        },
        {
          title: '所在大板',
          key: 'carNo',
          dataIndex: 'carNo'
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          slots: {
            customRender: 'action'
          }
        }
      ],
      abnormalColumns: [
        {
          title: '异常类型',
          dataIndex: 'exceptionType.label',
          key: 'exceptionType.label',
          ellipsis: true
        },
        {
          title: '异常原因',
          dataIndex: 'exceptionSubType.label',
          key: 'exceptionSubType.label',
          ellipsis: true
        },
        {
          title: '异常说明',
          dataIndex: 'exceptionExplain',
          key: 'exceptionExplain'
        },
        {
          title: '车架号',
          dataIndex: 'carNo',
          key: 'carNo'
        },
        {
          title: '上报时间',
          dataIndex: 'explainTime',
          key: 'explainTime'
        },
        {
          title: '图片',
          width: 200,
          dataIndex: 'fileUrl',
          key: 'fileUrl',
          slots: {
            customRender: 'fileUrl'
          }
        },
        {
          title: '上报人名',
          dataIndex: 'explainer',
          key: 'exceptionExplain'
        },
        {
          title: '上报机构',
          dataIndex: 'orgName',
          key: 'orgName'
        }],
      inspectionColumns: [
        {
          title: '验车人',
          dataIndex: 'driverName',
          width: 100
        },
        {
          title: '台数',
          dataIndex: 'orderVehicleIds.length',
          width: 100
        },
        {
          title: '车牌号',
          dataIndex: 'orderVehicles',
          slots: {
            customRender: 'orderVehicles'
          }
        },
        {
          title: '虚拟号码',
          dataIndex: 'virtualNumber',
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      abnormaltotal: 0,
      evaluationList: [], // 评论列表
      replyList: [], // 回复信息
      dataLeft: [],
      abnormalDataLeft: [],
      inspectionData: [], // 验车信息数据
      detailData: {},
      tableDetail: {},
      pagination: {
        current: 1,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '20', '100'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      abnormalPagination: {
        current: 1,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      inspectionPagination: {
        pageSize: 4,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['4', '8', '10'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      transportCart: [],
      tagList: [],
      loading: false,
      abnormalLoading: false,
      abnormalModal: false,
      visible: true,
      position: ref(null),
      carIdList: [],
      vehicleTable: ref(null),
      vehicleGps: ref(null),
      settleAccounts: ref(null),
      shippingRef: ref(null),
      inspectionCommission: ref(null),
      vehicleVisible: true,
      carrierType: { label: '' },
      salesman: '',
      pushTime: '',
      orderVehicleTotalNum: 0,
      orderVehicleTransNum: 0,
      orderVehicleNotTransNum: 0
    })
    const formState = reactive({
      inStockType: 0,
      insignia: ''
    })
    const current = ref(1)
    const loadData = async () => {
      vehicleDetail(state.id, {}).then(res => {
        if (res.code === 10000) {
          state.detailData = res.data
          state.transportCart = res.data.vehicles
          state.carrierType = res.data.carrierType
          state.salesman = res.data.salesman
          state.pushTime = res.data.pushTime
          state.orderVehicleTotalNum = res.data.orderVehicleTotalNum
          state.orderVehicleTransNum = res.data.orderVehicleTransNum
          state.orderVehicleNotTransNum = res.data.orderVehicleNotTransNum
          state.tagList = res.data.vehicles
          state.carIdList = res.data.vehicles
          state.orderStatus = res.data.orderStatus.value
          state.startAddress = res.data.startAddress
          state.endAddress = res.data.endAddress
        }
      })
      searchVehicleData()
      abnormalReport()
      inspectionLoadData()
    }
    // 获取车辆
    const getVehiclesData = () =>{
      getVehicles(state.id).then(res=>{
        if(res.code === 10000){
          state.detailData.vehicleList = []
          res.data.forEach(item=>{
            state.detailData.vehicleList.push({vinNo:item})
          })
        }
      })
    }
    const modalVisible = () => {
      state.orderInfoShow = true
      setTimeout(() => {
        orderInfoRef.value.onmodalVisible(true, state.carrierType.value, state.id)
      }, 100)
    }
    const settleAccountsVisible = () => {
      state.settleAccounts.onmodalVisible(true)
    }
    const evaluationLook = () => {
      state.evaluation = true
      orderRemark(state.id)
        .then(res => {
          if (res.code === 10000) {
            state.evaluationList = res.data.remarks
            state.proposal = res.data.message
            state.remarkTime = res.data.time
          }
        }).catch(err => console.log(err))
      serOrderMessage()
    }
    const serOrderMessage = () => {
      orderMessage(state.id)
        .then(res => {
          if (res.code === 10000) {
            state.replyList = res.data
          }
        }).catch(err => console.log(err))
    }
    const setReply = () => {
      replyMessage({
        message: state.reply,
        orderId: state.id,
        status: 1,
        type: { label: '内部人员', value: 1 }
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.data)
          serOrderMessage()
          state.reply = ''
        }
      }).catch(err => console.log(err))
    }
    // 验车信息列表
    const inspectionLoadData = () => {
      driverPage({
        current: 1,
        orderId: state.id
      }).then(res => {
        if (res.code === 10000) {
          state.inspectionData = res.data.records
          state.inspectionPagination.total = res.data.total
          state.inspectionLoading = false
        }
      }).catch(err => console.log(err))
      // 备注信息获取
      remarkData(state.id)
        .then(res => {
          if (res.code === 10000) state.remark = res.data
        }).catch(err => { console.log(err) })
    }
    const searchVehicleData = () => {
      state.loading = true
      incompleteDetail(state.id, {
        ...formState,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.dataLeft = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })
    }
    const resetData = () => {
      formState.inStockType = 0
      formState.carNo = ''
      searchVehicleData()
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      searchVehicleData()
    }
    // 验车列表分页
    const handleChange = (pag, filters, sorter) => {
      state.driverPagination.current = pag.current
      state.driverPagination.pageSize = pag.pageSize
      loadData()
    }
    const searchVehicles = () => {
      state.pagination.current = 1
      searchVehicleData()
    }
    onMounted(loadData)

    const historyPosition = (index) => {
      state.position.oncarid(state.carIdList[index].transTruckId)
      state.position.onvisible(state.visible)
    }
    const replaceLoad = () => {
      loadData()
    }

    const checkOut = (id) => {
      state.vehicleTable.onvisible(state.vehicleVisible)
      state.vehicleTable.onorderVehicleId(id)
    }
    const orderVehicleGps = (id) => {
      state.vehicleGps.onvisible(state.vehicleVisible)
      state.vehicleGps.onorderVehicleId(id)
    }
    const abnormalReportMode = () => {
      abnormalTableRef.value.openWindow(true)
      abnormalTableRef.value.loadData(state.id, undefined)
      // state.abnormalModal = true
      // abnormalReport()
    }
    const abnormalReport = () => {
      state.abnormalLoading = true
      orderExceptionPage(state.id, {
        orderId: state.id,
        current: state.abnormalPagination.current,
        size: state.abnormalPagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.abnormalDataLeft = res.data.records
          state.abnormalPagination.current = res.data.current
          state.abnormalPagination.pageSize = res.data.size
          state.abnormalPagination.total = res.data.total
          state.abnormaltotal = res.data.records.length
          state.abnormalLoading = false
        }
      })
    }
    const QRCshow = () => {
      state.status = 0
      getVehiclesData()
      setTimeout(() => {
        appleQRCref.value.appletShow = true
        appleQRCref.value.appletChange()
      }, 50)
      // state.QRC = true
      // state.QRCloading = true
      // const wXprogramQRcode = { scene: state.id, type: 2 }
      // QRCurl(wXprogramQRcode)
      //   .then(res => {
      //     if (res.code === 10000) {
      //       state.QRCurl = 'data:image/png;base64,' + res.data
      //       if (state.QRCurl !== '' || state.QRCurl !== null || state.QRCurl !== undefined) state.QRCloading = false
      //     }
      //     // console.log(res)
      //   })
      //   .catch(err => console.log(err))
    }
    const QRCpayImg = () => {
      state.status = 1
      state.QRC = true
      state.QRCloading = true
      state.payUrl = ''
      if (state.QRCpay === '') state.QRCloading = true
      if (state.payType === 0) {
        QRCpayurl({
          orderId: state.id,
          payType: '1'
        }).then(res => {
          if (res.code === 10000) {
            state.payUrl = res.data.qrCode
            state.QRCcontext = ''
          }
          if (res.code === 10001) {
            state.QRCcontext = res.msg
          }
        }).finally(() => { state.QRCloading = false })
      } else {
        QRCpayurlYST({
          orderId: state.id,
          payType: '1',
          payMethod: state.payType
        }).then(res => {
          if (res.code === 10000) {
            state.payUrl = res.data.qrCode
            state.QRCcontext = ''
          }
          if (res.code === 10001) {
            state.QRCcontext = res.msg
          }
        }).finally(() => { state.QRCloading = false })
      }
    }
    // 二维码
    const downQRC = () => {
      const image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
        const a = document.createElement('a') // 生成一个a元素
        const event = new MouseEvent('click') // 创建一个单击事件
        a.download = '二维码' || 'pic' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      if (state.status === 0) image.src = state.QRCurl
      else {
        const evidenceDOM = document.querySelector('#QRCpayImg')
        // 保存为图片
        html2canvas(evidenceDOM, {
          // width: 310,
          // height: 330.3,
          // windowWidth: document.body.scrollWidth,
          // windowHeight: document.body.scrollHeight,
          // x: 0,
          // y: 370
          // allowTaint: true, ///允许跨域图片
          // useCORS: true, //是否尝试使用CORS从服务器加载图像
        }).then(resolve => {
          // console.log(window.screen.availWidth, window.screen.availHeight)  // 电脑分辨率 1920 1040
          resolve.style.width = '200px'
          const imgUrl = resolve.toDataURL('image/png')
          state.evidenceSrc = imgUrl
          // console.log(resolve)
        })
        setTimeout(() => {
          const a = document.createElement('a')
          a.download = 'pic'
          a.href = state.evidenceSrc
          a.click()
        }, 500)
      }
    }
    // 查看图片
    const vehicleImg = (orderVehicleId, vinNo) => {
      vehicleImgRef.value.vehicleImg(orderVehicleId, vinNo)
    }
    // 异常列表回传
    const abnormal = e => {
      state.abnormalReportShow = false
    }
    const onLog = data => {
      console.log('data', data);
      state.logShow = true
      state.chatObjct = data
    }
    const lookPlan = record => {
      state.orderId = record.orderId
      state.orderVehicleId = record.orderVehicleId
      setTimeout(() => {
        state.shippingRef.loadData()
      }, 10)
    }
    const abnormalOpen = record => {
      state.tableDetail = record
      state.abnormalReportShow = true
    }
    const inspectionCommissionClick = orderVehicleId => {
      state.inspectionCommission.onVisible(true)
      state.inspectionCommission.onInspectionCommission(orderVehicleId)
    }
    const openPayQRC = () => {
      let obj = {
        startAddress: state.startAddress,
        endAddress: state.endAddress,
        orderId: state.id,
        vehicleList: state.dataLeft
      }
      setTimeout(() => {
        payQRCref.value.openWindow(obj)
      }, 10)
    }
    return {
      ...toRefs(state),
      QRCshow,
      payQRCref,
      abnormalTableRef,
      orderInfoRef,
      appleQRCref,
      vehicleImgRef,
      abnormalOpen,
      QRCpayImg,
      downQRC,
      vehicleImg,
      formState,
      abnormalReport,
      inspectionCommissionClick,
      current,
      setReply,
      abnormal,
      abnormalReportMode,
      loadData,
      historyPosition,
      searchVehicleData,
      evaluationLook,
      checkOut,
      orderVehicleGps,
      modalVisible,
      resetData,
      replaceLoad,
      settleAccountsVisible,
      handleTableChange,
      searchVehicles,
      handleChange,
      onLog,
      openPayQRC,
      lookPlan
    }
  }
}
</script>

<style lang='less' scoped>
:deep(.ant-image) {
  height: 150px;
}

:deep(.ant-image-img) {
  height: 150px;
}

.page-header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  &-right {
    display: flex;

    &-text {
      height: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 40px;

      span {
        margin-left: 5px;
        font-size: 14px;
        color: #666666;
      }
    }
  }
}

.showbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 140px;
  margin: 10px 0px;
  background-color: white;

  &-min {
    display: flex;
    justify-content: space-between;
    height: 62px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;

      p {
        margin: 0;
        font-size: 14px;
        color: #666666;
      }

      .num-data {
        font-size: 24px;
        position: absolute;
        right: -50px;
        top: -7px;
      }

      span {
        font-size: 24px;

        img {
          margin-left: 10px;
        }
      }

      a {
        font-size: 14px;
        color: #666666;
      }
    }
  }
}

.transport-cart {
  height: 80px;
  margin-top: 6px;
  display: flex;
  justify-content: space-around;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
    position: relative;

    .copy {
      position: absolute;
      left: 0;
      top: 52%;
    }

    p {
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 16px;
    color: #000000;
  }

  span {
    font-size: 14px;
    color: #666666;
  }
}

.page {
  position: absolute;
  // bottom: 20px;
  right: 0;
}

.contract {
  &-title {
    text-align: center;
  }

  &-head {
    &-label {
      font-size: 16px;
      font-weight: 600;
      text-align: right;
    }

    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-align: left;
      text-indent: 1em;
    }
  }

  &-content {
    font-size: 16px;
    text-indent: 2em;

    h1 {
      text-align: center;
    }

    &-sort {
      font-size: 18px;
      font-weight: 600;
    }

    &-label {
      font-size: 16px;
      font-weight: 600;
      text-indent: 2em;
    }

    &-value {
      font-size: 16px;
      border-bottom: 1px solid #000;
      text-indent: 1em;
      text-align: left;
      height: 24px;
    }

    &-right {
      text-align: right;
      padding-right: 2em;
    }
  }

  &-footer {
    text-indent: 2em;
    margin-bottom: 8px;

    &-label {
      font-size: 16px;
      font-weight: 600;
    }

    &-value {
      font-size: 16px;
      text-indent: 1em;
    }
  }

  &-seal {
    width: 230px;
    margin-top: -200px;
    margin-left: 60%;
  }
}

.QRC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.evaluation-data {
  display: flex;

  .left-evaluation {
    width: 60%;
    padding: 0 10px;
    border-right: 1px solid #ccc;

    .title-data {
      padding: 10px 5px;
      background-color: #f5f5f5;
    }
  }

  .right-reply {
    flex: 1;
    padding: 10px;

    .send-btn {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
